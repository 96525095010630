import {
	Alert,
	AlertExamination,
	AlertMessage,
	AlertReason,
	AlertSeverity,
	AlertState,
} from "../entities/alert.model";
import {
	TableTemplateButtons,
	TableTemplateButtonsEvent,
	TableTemplateTag,
} from "../components/table/table-template.model";
import { Formatter } from "../../services/formatter/formatter.service";
import { TagSeverityType } from "primereact/tag";

export class AlertRow implements Alert {
	id: number;
	kannactId: string;
	firstName: string;
	lastName: string;
	coachId: number;
	entityId: number;
	institutionId: number;
	isTest: boolean;
	type: string;
	severity: AlertSeverity;
	reason: AlertReason;
	details: string;
	date: string; // Date
	version: number;
	resolutionType: string;
	examinations: AlertExamination[] | null;
	reachouts: number;
	lastAttempt: string; // Date
	state: AlertState;
	messages: AlertMessage[];
	// Custom fields
	entity: Alert;
	name: string;
	priority: TableTemplateTag;
	actionsTemplate: TableTemplateButtons;

	constructor(params: Alert, resolveFn: (e: TableTemplateButtonsEvent) => void) {
		this.id = params.id;
		this.kannactId = params.kannactId;
		this.firstName = params.firstName;
		this.lastName = params.lastName;
		this.coachId = params.coachId;
		this.entityId = params.entityId;
		this.institutionId = params.institutionId;
		this.type = params.type;
		this.severity = params.severity;
		this.details = params.details;
		this.date = params.date;
		this.version = params.version;
		this.resolutionType = params.resolutionType;
		this.examinations = params.examinations;
		this.reachouts = params.reachouts;
		this.lastAttempt = params.lastAttempt;
		this.reason = params.reason;
		this.isTest = params.isTest;
		this.state = params.state;
		this.messages = params.messages;

		// Custom fields
		this.entity = params;
		this.name = Formatter.fullName(params.firstName, params.lastName);
		this.priority = {
			severity: this.setPrioritySeverity(params.severity),
			value: `ENUMS.ALERT_SEVERITY.${params.severity}`,
			rounded: true,
			uppercase: true,
		};
		this.actionsTemplate =
			params.reason === AlertReason.CRITICAL_READING
				? [
						{
							id: "resolveAction",
							className: "p-button-xs p-button-rounded",
							label: "PARTICIPANTS.ALERTS.TABLE.ACTION_RESOLVE",
							disabled: false,
							onClick: resolveFn,
						},
					]
				: [];
	}

	private setPrioritySeverity = (severity: AlertSeverity): TagSeverityType => {
		let value: TagSeverityType = "danger";
		if (severity === AlertSeverity.LOW) {
			value = "success";
		} else if (severity === AlertSeverity.MEDIUM) {
			value = "warning";
		}
		return value;
	};
}
