import { useEffect, useState } from "react";
import UiTable from "../../../../components/table/Table.component";
import {
	TableColumnTemplate,
	TableColumn,
} from "../../../../models/components/table/table-column.model";
import {
	TablePagination,
	ITablePagination,
} from "../../../../models/components/table/table-pagination.model";
import { TableMessages } from "../../../../models/components/table/table-message.model";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../../../models/components/field-message.model";
import { SentEmailRow } from "../../../../models/pages/sent-emails.model";
import { SentEmail } from "../../../../models/entities/sent-email.model";
import { EndpointsService } from "../../../../services/endpoints/endpoints.service";
import { AxiosError } from "axios";
import UiModalSentEmails from "../../../../components/modal-sentemails/ModalSentEmails.component";
import { UiModalSentEmailsProps } from "../../../../models/components/modal-sentemails.model";
import { TableTemplateButtonsEvent } from "../../../../models/components/table/table-template.model";
import { BackendPagination } from "../../../../models/pagination.model";
import { ParticipantProfile } from "../../../../models/entities/participant.model";
import {
	TableFilterType,
	ITableFilter,
	ITableHeaderFilter,
	TableFilterFormData,
} from "../../../../models/components/table/table-filter.model";
import { ITableHeaderSearch } from "../../../../models/components/table/table-search.model";
import { FilterService } from "../../../../services/filter/filter.service";
import { Formatter } from "../../../../services/formatter/formatter.service";
import { useTranslation } from "react-i18next";

const ParticipantSentEmails = ({ participant }: { participant: ParticipantProfile }) => {
	const { t } = useTranslation("common");

	const templateOrigin = (row: SentEmailRow): React.ReactNode => {
		return (
			<span className="text">
				{row.origin
					? t(`PARTICIPANT.MESSAGES.SENT_EMAILS.ORIGINS.${row.origin}`)
					: Formatter.NO_DATA}
			</span>
		);
	};

	const columns: TableColumn[] = [
		{
			field: "timestamp",
			title: "PARTICIPANT.MESSAGES.SENT_EMAILS.TABLE.COLUMNS.DATE",
			template: TableColumnTemplate.DATE,
			templateOptions: {
				dateFormat: {
					day: "2-digit",
					month: "2-digit",
					year: "2-digit",
					hour: "2-digit",
					minute: "2-digit",
				},
			},
			sortable: true,
		},
		{
			field: "subject",
			title: "PARTICIPANT.MESSAGES.SENT_EMAILS.TABLE.COLUMNS.SUBJECT",
		},
		{
			field: "origin",
			title: "PARTICIPANT.MESSAGES.SENT_EMAILS.TABLE.COLUMNS.ORIGIN",
			template: TableColumnTemplate.CUSTOM,
			templateOptions: {
				customTemplate: templateOrigin,
			},
		},
		{
			field: "from",
			title: "PARTICIPANT.MESSAGES.SENT_EMAILS.TABLE.COLUMNS.FROM",
		},
		{
			field: "actionsTemplate",
			template: TableColumnTemplate.BUTTONS,
			alignment: "right",
		},
	];

	const [rows, setRows] = useState<SentEmailRow[]>([]);

	const participantFilter: ITableFilter = {
		field: "participantId",
		type: TableFilterType.NUMBER,
		value: [`${participant.id}`],
	};

	const onlyEmailsFilter: ITableFilter = {
		field: "channel",
		type: TableFilterType.STRING,
		value: ["PAUBOX", "MANDRILL"],
	};

	const dateFilter: ITableFilter = {
		field: "timestamp",
		type: TableFilterType.DATE_NAVIGATOR,
		options: {
			columnClass: "col-12 col-md-3 col-lg-3",
			props: {
				label: "PARTICIPANT.MESSAGES.SENT_EMAILS.TABLE.FILTER_DATE",
				options: [],
				filter: true,
			},
		},
		value: undefined,
	};

	const [filters, setFilters] = useState<ITableHeaderFilter | undefined>({
		filters: [dateFilter],
	});

	const [count, setCount] = useState<number>(0);
	const [pagination, setPagination] = useState<TablePagination>({
		first: 0,
		rows: 10,
		page: 0,
		sortField: "timestamp",
		sortOrder: -1,
		search: undefined,
		filters: filters?.filters,
	});

	const messages = new TableMessages();
	const [message, setMessage] = useState<UiFieldMessageProps>({
		severity: FieldMessageSeverity.INFO,
		label: messages.empty,
	});

	const tableSearch: ITableHeaderSearch = {
		fn: (value: string | null | undefined) => {
			const newPagination: TablePagination = {
				...pagination,
				search: value?.trim(),
				page: 0,
				first: 0,
			};

			setPagination(newPagination);

			getData(newPagination);
			getCount(newPagination);
		},
	};

	const submitFilters = (value: TableFilterFormData) => {
		const newFilters = FilterService.updateFiltersValues(
			(filters as ITableHeaderFilter).filters,
			value
		);

		setFilters({
			...filters,
			filters: newFilters,
		});

		const newPagination: TablePagination = {
			...pagination,
			filters: newFilters,
			page: 0,
			first: 0,
		};

		setPagination(newPagination);

		getData(newPagination);
		getCount(newPagination);
	};

	useEffect(() => {
		getData(pagination);
		getCount(pagination);
	}, []);

	const getData = async (event: TablePagination): Promise<void> => {
		setRows([]);

		// Set the loading spinner
		setMessage({
			severity: FieldMessageSeverity.LOADING,
			label: messages.loading,
		});

		const newFilters = [...(event.filters || []), onlyEmailsFilter, participantFilter].map(
			(filter) => {
				if (filter.field === "timestamp" && filter.value) {
					return {
						...filter,
						value: Formatter.dateToISO(filter.value!),
					};
				}

				return filter;
			}
		);

		const newEvent = {
			...event,
			filters: newFilters,
		};

		const config = {
			config: { params: new BackendPagination(newEvent) },
		};

		await EndpointsService.dataRetriever
			.getSentEmails(config)
			.then((response: SentEmail[]) => {
				if (response?.length > 0) {
					response.map((item) => {
						item.actionFn = showModal.bind(this);
					});

					setRows(response.map((item) => new SentEmailRow(item)));
				} else {
					setMessage({
						severity: FieldMessageSeverity.INFO,
						label: messages.empty,
					});
				}
			})
			.catch((error: AxiosError) =>
				setMessage({
					severity: FieldMessageSeverity.DANGER,
					label: messages.error,
				})
			);
	};

	const getCount = async (event: TablePagination): Promise<void> => {
		setCount(0);

		const newFilters = [...(event.filters || []), onlyEmailsFilter, participantFilter].map(
			(filter) => {
				if (filter.field === "timestamp" && filter.value) {
					return {
						...filter,
						value: Formatter.dateToISO(filter.value!),
					};
				}

				return filter;
			}
		);

		// Update pagination object
		const updatedEvent: ITablePagination = { ...event, filters: newFilters };

		const queryParams = new BackendPagination(updatedEvent);
		await EndpointsService.dataRetriever
			.getSentEmailsCount({
				config: {
					params: {
						search: queryParams.search,
						filters: queryParams.filters,
					},
				},
			})
			.then((response: number) => {
				if (response || response === 0) {
					setCount(response);
				}
			})
			.catch((error: AxiosError) => {
				console.error("Sent emails: Error getting the count");
			});
	};

	const onPagination = async (event: TablePagination): Promise<void> => {
		const newPagination: TablePagination = {
			...pagination,
			page: event.page,
			rows: event.rows,
			sortField: event.sortField,
			sortOrder: event.sortOrder,
			first: event.first,
		};
		setPagination(newPagination);

		getData(newPagination);
	};

	/**
	 * MODAL: SENT EMAIL
	 */

	const showModal = async (event: TableTemplateButtonsEvent): Promise<void> => {
		const row: SentEmailRow = event.row;

		setModalProps({
			...modalProps,
			entity: row.entity,
			isVisible: true,
		});
	};

	const closeModalSentEmails = (): void => {
		setModalProps({ ...modalProps, isVisible: false });
	};

	// Modal reminder: state
	const [modalProps, setModalProps] = useState<UiModalSentEmailsProps>({
		id: null,
		entity: undefined,
		isVisible: false,
		closeModal: closeModalSentEmails,
	});

	return (
		<>
			<UiTable
				useAsCard={false}
				dataKey="id"
				title="PARTICIPANT.MESSAGES.SENT_EMAILS.TITLE"
				columns={columns}
				value={rows}
				message={message}
				isServerPaginated={true}
				pagination={pagination}
				paginationFn={onPagination}
				totalRecords={count}
				search={tableSearch}
				filter={filters}
				filterFn={submitFilters}
			/>

			{modalProps.isVisible && <UiModalSentEmails {...modalProps}></UiModalSentEmails>}
		</>
	);
};

export default ParticipantSentEmails;
