import { MedicalReportGetRequest } from "../../models/endpoints/metriport-endpoint.model";
import HttpService from "../http/http.service";
import { MedicalReport } from "../../models/entities/metriport.model";

const httpService = new HttpService(process.env.REACT_APP_METRIPORT_API_URL!);

export const MetriportEndpointsService = {
	getMedicalReport: async (request: MedicalReportGetRequest): Promise<MedicalReport> =>
		httpService.get(`/medical-report/${request.participantId}`, {
			config: {
				params: {
					include_content: request.includeContent,
				},
			},
		}),
	generateMedicalReport: async (participantId: number): Promise<void> =>
		httpService.post(`/generate-medical-report`, {
			body: { participant_id: String(participantId) },
		}),
};
