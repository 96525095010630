import { useTranslation } from "react-i18next";
import { ParticipantProfile } from "../../../models/entities/participant.model";
import { Dialog } from "primereact/dialog";
import ParticipantDetailExternalLink from "../details/ParticipantDetailExternalLink";
import UiButton from "../../../components/button/Button";

const ModalSurveyLinks = ({
	isVisible,
	participant,
	closeModal,
}: {
	isVisible: boolean;
	participant: ParticipantProfile;
	closeModal: () => void;
}) => {
	const { t } = useTranslation("common");

	return (
		<Dialog
			header={t("PARTICIPANT.SURVEYS.MODAL_LINKS.TITLE")}
			visible={isVisible}
			breakpoints={{ "960px": "75vw", "640px": "100vw" }}
			style={{ width: "70vw" }}
			modal={true}
			focusOnShow={false}
			onHide={closeModal}
		>
			{/* Intake link */}
			<ParticipantDetailExternalLink
				href={participant.intakeSurveyLink}
				title="PARTICIPANT.SURVEYS.MODAL_LINKS.intakeLink"
			/>

			{/* Insurance link */}
			<ParticipantDetailExternalLink
				href={participant.insuranceSurveyLink}
				title="PARTICIPANT.SURVEYS.MODAL_LINKS.insuranceLink"
			/>

			{/* Insurance link */}
			<ParticipantDetailExternalLink
				href={participant.hipaaReleaseSurveyLink}
				title="PARTICIPANT.SURVEYS.MODAL_LINKS.hipaaLink"
			/>

			{/* Insurance no consent link */}
			<ParticipantDetailExternalLink
				href={participant.insuranceNoConsentLink}
				title="PARTICIPANT.SURVEYS.MODAL_LINKS.insuranceNoConsentLink"
			/>

			{/* Standalone consent link */}
			<ParticipantDetailExternalLink
				href={participant.standaloneConsentSurveyLink}
				title="PARTICIPANT.SURVEYS.MODAL_LINKS.standaloneConsentLink"
			/>

			{/* Intake no consent link */}
			<ParticipantDetailExternalLink
				href={participant.intakeNoConsentSurveyLink}
				title="PARTICIPANT.SURVEYS.MODAL_LINKS.intakeNoConsentLink"
			/>

			{/* Rapid 3 link */}
			<ParticipantDetailExternalLink
				href={participant.rapid3SurveyLink}
				title="PARTICIPANT.SURVEYS.MODAL_LINKS.rapid3SurveyLink"
			/>

			{/* Diabetes pre-education link */}
			<ParticipantDetailExternalLink
				href={participant.diabetesPreEducationLink}
				title="PARTICIPANT.SURVEYS.MODAL_LINKS.diabetesPreEducationLink"
			/>

			{/* Financial assistance application link */}
			<ParticipantDetailExternalLink
				href={participant.financialAssistanceApplicationLink}
				title="PARTICIPANT.SURVEYS.MODAL_LINKS.financialAssistanceApplicationLink"
			/>
			
			{/* Social needs screening tool link */}
			<ParticipantDetailExternalLink
				href={participant.socialNeedsScreeningToolLink}
				title="PARTICIPANT.SURVEYS.MODAL_LINKS.socialNeedsScreeningToolLink"
			/>

			<div className="action-buttons">
				<UiButton
					id="modalBtnClose"
					label="UI_COMPONENTS.BUTTONS.CLOSE"
					className="p-button-outlined p-button-rounded"
					type="button"
					onClick={closeModal}
				></UiButton>
			</div>
		</Dialog>
	);
};

export default ModalSurveyLinks;
