import { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import UiInputDate from "../../../../../../components/input-date/InputDate.component";
import { Validations } from "../../../../../../services/form/validations.service";
import UiInputTextarea from "../../../../../../components/input-textarea/InputTextarea.component";
import UiSelect from "../../../../../../components/select/Select.component";
import UiSelectMultiple from "../../../../../../components/select-multiple/SelectMultiple.component";
import UiFieldMessage from "../../../../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../../../../models/components/field-message.model";
import UiButton from "../../../../../../components/button/Button";
import { useNextAppointment } from "../../../../../../hooks/useNextAppointment";
import NoteNextAppointment from "./NoteNextAppointment";
import CustomGoals from "../../custom-goals/CustomGoals";
import { NoteFormData } from "../../../../../../models/notes/note-form";
import { Note, NoteTopics, NoteStateOfChange } from "../../../../../../models/notes/note";
import UiInputRadio from "../../../../../../components/input-radio/InputRadio.component";
import { EndpointsService } from "../../../../../../services/endpoints/endpoints.service";
import { NoteHelper } from "../../../../../../services/helpers/note-helper";
import NoteDraft from "./NoteDraft";
import { useSmartGoals } from "../../../../../../hooks/custom-goals/useSmartGoals";
import { useLongTermGoals } from "../../../../../../hooks/custom-goals/useLongTermGoals";
import { ListOption } from "../../../../../../models/misc.model";
import { ParticipantProfile } from "../../../../../../models/entities/participant.model";
import { FaExclamationTriangle } from "react-icons/fa";
import NoteFormCustomField from "./NoteFormCustomField";

const NoteForm = ({
	participant,
	note,
	nextAppointmentQuery,
	smartGoalsQuery,
	longTermGoalsQuery,
	onSubmit,
	onCancel,
}: {
	participant: ParticipantProfile;
	note: Note | null;
	nextAppointmentQuery: ReturnType<typeof useNextAppointment>;
	smartGoalsQuery: ReturnType<typeof useSmartGoals>;
	longTermGoalsQuery: ReturnType<typeof useLongTermGoals>;
	onSubmit: () => void;
	onCancel: () => void;
}) => {
	const { t } = useTranslation("common");

	const [errorValidating, setErrorValidating] = useState<string | null>(null);
	const [errorGoals, setErrorGoals] = useState<boolean>(false);
	const [errorSubmitting, setErrorSubmitting] = useState<boolean>(false);

	const [formData, setFormData] = useState<NoteFormData>(
		NoteHelper.setNoteFormData(participant.id, note)
	);

	useEffect(() => {
		if (note) {
			// Editing a note
			NoteHelper.deleteDraft(participant.id);
		}
		setFormData(NoteHelper.setNoteFormData(participant.id, note));
	}, [note]);

	const handleSubmit = async () => {
		setErrorValidating(null);
		setErrorGoals(false);
		setErrorSubmitting(false);

		if (formData.type === "COACHING" && !NoteHelper.isValidCoachNote(formData)) {
			setErrorValidating(t("PARTICIPANT.DASHBOARD.NOTES.COACHING_CALL_MISSING_DATA_ERROR"));
		} else if (formData.type === "COACHING" && !NoteHelper.goalsAreValid(formData)) {
			setErrorGoals(true);
		} else if (formData.type === "OTHER" && !NoteHelper.isValidOtherNote(formData)) {
			setErrorValidating(t("PARTICIPANT.DASHBOARD.NOTES.OTHER_NOTE_MISSING_DATA_ERROR"));
		} else {
			if (note) {
				// Update
				await update();
			} else {
				// Create
				await create();
			}
		}
	};

	const create = async () => {
		await EndpointsService.dataRetriever
			.createParticipantNotes({
				body: [
					formData.type === "COACHING"
						? NoteHelper.coachingNote(participant.id, formData)
						: NoteHelper.otherNote(participant.id, formData),
				],
			})
			.then(() => {
				NoteHelper.deleteDraft(participant.id);
				setFormData(NoteHelper.setNoteFormData(participant.id, null));
				onSubmit();
			})
			.catch(() => setErrorSubmitting(true));
	};

	const update = async () => {
		await EndpointsService.dataRetriever
			.editParticipantNote({
				body:
					formData.type === "COACHING"
						? { ...NoteHelper.coachingNote(participant.id, formData), id: note!.id }
						: { ...NoteHelper.otherNote(participant.id, formData), id: note!.id },
			})
			.then(() => {
				setFormData(NoteHelper.setNoteFormData(participant.id, null));
				onSubmit();
			})
			.catch(() => setErrorSubmitting(true));
	};

	return (
		<div className="participant_notes_form">
			<div className="participant_notes_form__title">
				{t("PARTICIPANT.DASHBOARD.NOTES.TITLE")}
			</div>

			<Form
				initialValues={formData}
				onSubmit={handleSubmit}
				render={({ handleSubmit, submitting, form }) => (
					<>
						<form onSubmit={handleSubmit}>
							{!note && (
								<NoteDraft
									participantId={participant.id}
									formData={formData}
								/>
							)}

							{/* Date of engagement */}
							<UiInputDate
								id="date"
								name="date"
								placeholder="PARTICIPANT.DASHBOARD.NOTES.DATE_OF_ENGAGEMENT"
								label="PARTICIPANT.DASHBOARD.NOTES.DATE_OF_ENGAGEMENT"
								onChange={(e) =>
									setFormData({
										...formData,
										date: e.target.value as Date | null,
									})
								}
								maxDate={new Date()}
								showTime={true}
								validations={[Validations.required]}
								disabled={submitting}
							/>

							{/* Note type: COACHING/OTHER */}
							<div>
								<UiInputRadio
									id="type"
									name="type"
									display="inline"
									label="PARTICIPANT.DASHBOARD.NOTES.TYPE"
									onChange={(e) => {
										setErrorValidating(null);
										setErrorGoals(false);
										setErrorSubmitting(false);
										setFormData({ ...formData, type: e.target.value });
										form.restart();
									}}
									validations={[Validations.required]}
									disabled={submitting}
									options={[
										{
											id: "COACHING",
											label: "PARTICIPANT.DASHBOARD.NOTES.COACHING_CALL",
										},
										{ id: "OTHER", label: "PARTICIPANT.DASHBOARD.NOTES.OTHER" },
									]}
								/>
							</div>

							{formData.type === "COACHING" && (
								<>
									{/* Subjective data */}
									<NoteFormCustomField
										fieldName="subjective"
										fieldLabel="S"
										tooltip="PARTICIPANT.DASHBOARD.NOTES.S"
									>
										<UiInputTextarea
											id="subjective"
											name="subjective"
											placeholder="PARTICIPANT.DASHBOARD.NOTES.SUBJECTIVE_DATA"
											onChange={(e) =>
												setFormData({
													...formData,
													subjective: e.target.value,
												})
											}
											disabled={submitting}
										/>
									</NoteFormCustomField>

									{/* Objective data */}
									<NoteFormCustomField
										fieldName="objective"
										fieldLabel="O"
										tooltip="PARTICIPANT.DASHBOARD.NOTES.O"
									>
										<UiInputTextarea
											id="objective"
											name="objective"
											placeholder="PARTICIPANT.DASHBOARD.NOTES.OBJECTIVE_DATA"
											onChange={(e) =>
												setFormData({
													...formData,
													objective: e.target.value,
												})
											}
											disabled={submitting}
										/>
									</NoteFormCustomField>

									{/* Assessment: State of change + Assessment */}
									<NoteFormCustomField
										fieldName="assessment"
										fieldLabel="A"
										tooltip="PARTICIPANT.DASHBOARD.NOTES.A"
									>
										<>
											{/* Assessment */}
											<UiInputTextarea
												id="assessment"
												name="assessment"
												placeholder="PARTICIPANT.DASHBOARD.NOTES.ASSESSMENT"
												label="PARTICIPANT.DASHBOARD.NOTES.ASSESSMENT"
												onChange={(e) =>
													setFormData({
														...formData,
														assessment: e.target.value,
													})
												}
												disabled={submitting}
											/>
											{/* State of change */}
											<UiSelect
												id="stateOfChange"
												name="stateOfChange"
												placeholder="PARTICIPANT.DASHBOARD.NOTES.STATE_OF_CHANGE"
												label="PARTICIPANT.DASHBOARD.NOTES.STATE_OF_CHANGE"
												className="w-75"
												onChange={(e) =>
													setFormData({
														...formData,
														stateOfChange: e.target.value,
													})
												}
												disabled={submitting}
												showClear
												options={Object.keys(NoteStateOfChange).map(
													(x) =>
														new ListOption({
															id: x,
															label: `PARTICIPANT.DASHBOARD.NOTES.STATE_OF_CHANGE_OPTIONS.${x}`,
														})
												)}
											/>
										</>
									</NoteFormCustomField>

									{/* Plan */}
									<NoteFormCustomField
										fieldName="plan"
										fieldLabel="P"
										tooltip="PARTICIPANT.DASHBOARD.NOTES.P"
									>
										<UiInputTextarea
											id="plan"
											name="plan"
											placeholder="PARTICIPANT.DASHBOARD.NOTES.PLAN"
											onChange={(e) =>
												setFormData({ ...formData, plan: e.target.value })
											}
											disabled={submitting}
										/>
									</NoteFormCustomField>

									<NoteNextAppointment
										nextAppointmentQuery={nextAppointmentQuery}
									/>
								</>
							)}

							{formData.type === "OTHER" && (
								<UiInputTextarea
									id="notes"
									name="notes"
									placeholder="PARTICIPANT.DASHBOARD.NOTES.NOTES"
									label="PARTICIPANT.DASHBOARD.NOTES.NOTES"
									onChange={(e) =>
										setFormData({ ...formData, notes: e.target.value })
									}
									disabled={submitting}
								/>
							)}

							{/* Topics */}
							<div>
								<UiSelectMultiple
									id="topics"
									name="topics"
									placeholder="PARTICIPANT.DASHBOARD.NOTES.TOPICS"
									label="PARTICIPANT.DASHBOARD.NOTES.TOPICS_YOU_SPOKE"
									appendTo="self"
									onChange={(e) =>
										setFormData({ ...formData, topics: e.target.value })
									}
									filter={true}
									options={Object.keys(NoteTopics).map(
										(x) =>
											new ListOption({
												id: x,
												label: `PARTICIPANT.DASHBOARD.NOTES.TOPICS_SPOKEN_OPTIONS.${x}`,
											})
									)}
									// maxSelectedLabels={1}
									disabled={submitting}
								/>
							</div>
						</form>

						<div>
							{formData.type === "COACHING" && (
								<CustomGoals
									noteId={note?.id || null}
									noteProgress={formData.noteProgress}
									smartGoalsQuery={smartGoalsQuery}
									longTermGoalsQuery={longTermGoalsQuery}
									onChange={(e) => setFormData({ ...formData, noteProgress: e })}
								/>
							)}

							{(errorValidating || errorGoals || errorSubmitting) && (
								<div className="form-message">
									<UiFieldMessage
										severity={FieldMessageSeverity.DANGER}
										label={
											errorValidating
												? errorValidating
												: errorGoals
													? "PARTICIPANT.DASHBOARD.NOTES.ERROR_GOALS"
													: "UI_COMPONENTS.FIELD_MESSAGE.HTTP.ERROR"
										}
									/>
								</div>
							)}

							<div className="action-buttons">
								{note && (
									<UiButton
										id="modalBtnCancel"
										label="UI_COMPONENTS.BUTTONS.CANCEL"
										className="p-button-outlined p-button-rounded"
										type="button"
										onClick={() => {
											NoteHelper.deleteDraft(participant.id);
											setFormData(
												NoteHelper.setNoteFormData(participant.id, null)
											);
											form.reset();
											onCancel();
										}}
										disabled={submitting}
									/>
								)}

								<UiButton
									label={
										submitting
											? "UI_COMPONENTS.BUTTONS.SAVING"
											: note
												? "UI_COMPONENTS.BUTTONS.SAVE_CHANGES"
												: "UI_COMPONENTS.BUTTONS.SAVE"
									}
									className="p-button-rounded"
									loading={submitting}
									disabled={submitting}
									type="submit"
									onClick={handleSubmit}
								/>
							</div>

							{/* EM Encounter link */}
							<div className="mt-2 d-flex align-items-center">
								<FaExclamationTriangle
									className="text-danger me-2"
									fontSize={"1.5rem"}
								/>
								<a
									href={participant.deviceSetupChecklistLink}
									target="_blank"
									rel="noreferrer noopener"
									className="k-link"
								>
									<span className="k-link-label text-bold">
										{t("PARTICIPANT.DASHBOARD.NOTES.DEVICE_SETUP_CHECKLIST")}
									</span>
								</a>
							</div>
							
							{/* EM Encounter link */}
							<div className="mt-2 d-flex align-items-center">
								<FaExclamationTriangle
									className="text-danger me-2"
									fontSize={"1.5rem"}
								/>
								<a
									href={participant.emEncounterSurveyLink}
									target="_blank"
									rel="noreferrer noopener"
									className="k-link"
								>
									<span className="k-link-label text-bold">
										{t("PARTICIPANT.DASHBOARD.NOTES.EM_ENCOUNTER_LINK")}
									</span>
								</a>
							</div>
						</div>
					</>
				)}
			/>
		</div>
	);
};

export default NoteForm;
