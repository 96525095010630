import { NoteProgress } from "../note-progress";

export interface Note {
	id: number;
	participantId: number;
	type: NoteType;
	date: string;
	coach: { id: number; firstName: string; lastName: string };
	subjective: string | null;
	objective: string | null;
	assessment: string | null;
	plan: string | null;
	stateOfChange: NoteStateOfChange | null;
	topics: NoteTopics[] | null;
	updatedOn: string;
	createdOn: string;
	noteProgress: NoteProgress[] | null;
}

export type NoteType = "COACHING" | "OTHER";

export enum NoteTopics {
	DEVICE_SETUP = "DEVICE_SETUP",
	INSURANCE_BILLING = "INSURANCE_BILLING",
	RPM_ALERT = "RPM_ALERT",
	HEALTH_SUMMARY_REPORT = "HEALTH_SUMMARY_REPORT",
	CONDITION_MANAGEMENT = "CONDITION_MANAGEMENT",
	MEDICATION_ADHERENCE = "MEDICATION_ADHERENCE",
	MENTAL_HEALTH = "MENTAL_HEALTH",
	SUPPLIES = "SUPPLIES",
	PREVENTIVE_CARE = "PREVENTIVE_CARE",
	TECH_SUPPORT = "TECH_SUPPORT",
	NONE = "NONE",
}

export enum NoteStateOfChange {
	PRE_CONTEMPLATION = "PRE_CONTEMPLATION",
	CONTEMPLATION = "CONTEMPLATION",
	PREPARATION = "PREPARATION",
	ACTION = "ACTION",
	MAINTENANCE = "MAINTENANCE",
	RELAPSE = "RELAPSE",
}
