import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { EndpointsService } from "../services/endpoints/endpoints.service";

export const useMedicalReport = ({
	participantId,
	includeContent,
	refetchInterval = 0,
}: {
	participantId: number;
	includeContent?: boolean;
	refetchInterval?: number;
}) => {
	const queryClient = useQueryClient();
	const queryKey = ["medical-report", { participantId, includeContent }];

	const query = useQuery(
		queryKey,
		async () => {
			try {
				const medicalReport = await EndpointsService.metriport.getMedicalReport({
					participantId,
					includeContent,
				});

				return medicalReport;
			} catch (error) {
				if (axios.isAxiosError(error) && error.response?.status === 404) {
					return null;
				}
				throw error;
			}
		},
		{
			refetchInterval: refetchInterval,
		}
	);

	return {
		...query,
		invalidate: () => queryClient.invalidateQueries(queryKey),
	};
};
