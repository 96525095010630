import { TableTemplateButtons } from "../components/table/table-template.model";
import { SentEmail } from "../entities/sent-email.model";

export class SentEmailRow {
	id: number;
	timestamp: Date;
	subject: string;
	origin: string;
	from: string;
	actionsTemplate: TableTemplateButtons;
	entity: SentEmail;

	constructor(params: SentEmail) {
		this.id = params.id;
		this.timestamp = params.timestamp;
		this.subject = params.subject ? params.subject : "-";
		this.origin = params.origin;
		this.from = params.from ? params.from : "-";
		this.entity = params;
		this.actionsTemplate = templateActions(params.actionFn);
	}
}

const templateActions = (actionFn: SentEmail["actionFn"]): TableTemplateButtons => [
	{
		id: "viewEmailButton",
		label: "PARTICIPANT.MESSAGES.SENT_EMAILS.TABLE.BUTTON_VIEW_EMAIL",
		className: "p-button-sm p-button-rounded",
		disabled: false,
		onClick: actionFn,
	},
];
