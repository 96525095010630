import { LongTermGoal, SmartGoal } from "../../models/custom-goals/custom-goals";
import {
	CustomGoalsActiveForm,
	CustomGoalsFormMode,
} from "../../models/custom-goals/custom-goals-misc";
import { List, ListOption } from "../../models/misc.model";
import { Note } from "../../models/notes/note";
import { CreateCoachingNote, CreateOherNote } from "../../models/notes/note-endpoints";
import { NoteFormData } from "../../models/notes/note-form";
import { getSessionStorageUser } from "../session/auth.service";
import * as DataStorageService from "../session/session.service";

const DRAFT_KEY = "pt-note-draft";

const activeSmartGoals = (data: SmartGoal[] | undefined, noteId: number | null) => {
	let result: SmartGoal[] = [];

	if (data && data.length > 0) {
		result = data.filter((x) => {
			let result = false;

			if (!noteId || !x?.support) {
				// Show only the active ones
				result = !x.deleted && !x.completed;
			} else {
				// Show only the active ones, except if you had selected a LT goal that now is deleted --> show it event if it's not active anymore
				result =
					(!x.deleted && !x.completed) ||
					((x.deleted || x.completed) && x.support.includes(x.id));
			}

			return result;
		});
	}

	return result;
};

const activeLongTermGoals = (
	data: LongTermGoal[] | undefined,
	mode: CustomGoalsFormMode,
	entity: SmartGoal | null
) => {
	let result: List = [];

	if (data && data.length > 0) {
		result =
			data
				?.filter((x) => {
					let result: boolean = false;
					if (mode === CustomGoalsFormMode.CREATE || !entity?.support) {
						// Show only the active ones
						result = !x.deleted && !x.completed;
					} else {
						// Show only the active ones, except if you had selected a LT goal that now is deleted --> show it event if it's not active anymore
						result =
							(!x.deleted && !x.completed) ||
							((x.deleted || x.completed) && entity.support.includes(x.id));
					}
					return result;
				})
				?.map((x) => {
					return new ListOption({
						id: x.id.toString(),
						label: x.name,
						className: x.completed
							? "text-success"
							: x.deleted
								? "text-danger"
								: undefined,
					});
				}) || [];
	}

	return result;
};

const isValidCoachNote = (data: NoteFormData) =>
	(data.subjective || data.objective || data.assessment || data.plan) && hasTopicsSelected(data);

const isValidOtherNote = (data: NoteFormData) => hasNote(data) && hasTopicsSelected(data);


const hasNote = (data: NoteFormData) => data.notes && data.notes.trim().length > 0;

const hasTopicsSelected = (data: NoteFormData) => data.topics.length > 0;

const goalsAreValid = (data: NoteFormData) =>
	data.noteProgress.find((x) => !x.selected && x.progress.progress) ? false : true;

const coachingNote = (participantId: number, data: NoteFormData): CreateCoachingNote => {
	return {
		participantId,
		type: "COACHING",
		date: data.date!.toISOString(),
		coach: { id: getSessionStorageUser().id! },
		subjective: data.subjective || null,
		objective: data.objective || null,
		assessment: data.assessment || null,
		plan: data.plan || null,
		stateOfChange: data.stateOfChange || null,
		topics: data.topics.length > 0 ? data.topics : null,
		noteProgress:
			data.noteProgress.filter((x) => x.selected).length > 0
				? data.noteProgress.filter((x) => x.selected).map((x) => x.progress)
				: null,
	};
};

const otherNote = (participantId: number, data: NoteFormData): CreateOherNote => {
	return {
		participantId,
		type: "OTHER",
		date: data.date!.toISOString(),
		coach: { id: getSessionStorageUser().id! },
		subjective: data.notes || null,
		topics: data.topics.length > 0 ? data.topics : null,
	};
};

const setNoteFormData = (participantId: number, data: Note | null): NoteFormData => {
	const draft = getDraft(participantId);
	if (!data && draft) {
		return { ...draft, date: new Date() };
	} else {
		return {
			type: data?.type || "COACHING",
			date: data?.date ? new Date(data.date) : new Date(),
			subjective: data?.subjective || null,
			notes: data?.subjective || null,
			objective: data?.objective || null,
			assessment: data?.assessment || null,
			plan: data?.plan || null,
			stateOfChange: data?.stateOfChange || null,
			topics: data?.topics || [],
			noteProgress:
				data?.noteProgress?.map((x) => {
					return { selected: !!x.id, progress: x };
				}) || [],
		};
	}
};

const setGoalsFormData = (
	noteProgress: CustomGoalsActiveForm[],
	smartGoals: SmartGoal[],
	noteId: number | null
) => {
	let result: CustomGoalsActiveForm[] = [];

	if (smartGoals.length) {
		result = smartGoals.map((x) => {
			const existingNp = noteProgress.find((y) => y.progress.smartGoalId === x.id);
			const item: CustomGoalsActiveForm = {
				selected: !!existingNp?.selected,
				progress: {
					id: existingNp?.progress.id ?? null,
					noteId: noteId ?? existingNp?.progress.noteId ?? null,
					smartGoalId: existingNp?.progress.smartGoalId ?? x.id,
					progress: existingNp?.progress.progress ?? null,
					createdOn: existingNp?.progress.createdOn ?? null,
				},
			};
			return item;
		});
	}

	return result;
};

const getDraftKey = (participantId: number) => `${DRAFT_KEY}/${participantId}`;

const saveDraft = (participantId: number, data: NoteFormData) =>
	DataStorageService.setItem(getDraftKey(participantId), data);

const getDraft = (participantId: number): NoteFormData | null =>
	DataStorageService.getItem(getDraftKey(participantId));

const deleteDraft = (participantId: number) =>
	DataStorageService.deleteItem(getDraftKey(participantId));

const deleteAllDraft = () => {
	Object.keys(sessionStorage)
		.filter(function (k) {
			return k.includes(DRAFT_KEY);
		})
		.forEach(function (k) {
			DataStorageService.deleteItem(k);
		});
};

export const NoteHelper = {
	isValidCoachNote,
	isValidOtherNote,
	goalsAreValid,
	coachingNote,
	otherNote,
	setNoteFormData,
	setGoalsFormData,
	saveDraft,
	getDraft,
	deleteDraft,
	deleteAllDraft,
	activeSmartGoals,
	activeLongTermGoals,
};
