import { useEffect, useState } from "react";
import { PageProps } from "../../../models/routing.model";
import { HelperService } from "../../../services/helpers/helper.service";
import UiTable from "../../../components/table/Table.component";
import { StoredCriteriaService } from "../../../services/session/stored-criteria.service";
import {
	ITablePagination,
	TablePagination,
} from "../../../models/components/table/table-pagination.model";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../../models/components/field-message.model";
import {
	TableColumn,
	TableColumnTemplate,
} from "../../../models/components/table/table-column.model";
import { TableMessages } from "../../../models/components/table/table-message.model";
import {
	ITableFilter,
	ITableHeaderFilter,
	TableFilterFormData,
	TableFilterType,
} from "../../../models/components/table/table-filter.model";
import { getSessionStorageUser } from "../../../services/session/auth.service";
import { BackendPagination } from "../../../models/pagination.model";
import { AlertRow } from "../../../models/pages/alert-row.model";
import {
	Alert,
	AlertDetails,
	AlertReason,
	AlertSeverity,
	AlertState,
} from "../../../models/entities/alert.model";
import { ListOption } from "../../../models/misc.model";
import { FilterService } from "../../../services/filter/filter.service";
import { Formatter } from "../../../services/formatter/formatter.service";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";
import { TableTemplateButtonsEvent } from "../../../models/components/table/table-template.model";
import { eParticipantStatus } from "../../../models/entities/participant.model";
import { Roles } from "../../../models/roles.model";
import { useTranslation } from "react-i18next";

const ParticipantsAlerts = (props: PageProps) => {
	const { t } = useTranslation("common");
	// Columns
	const setColumns = () => {
		const columns: TableColumn[] = [
			{
				field: "kannactId",
				title: "PARTICIPANTS.ALERTS.TABLE.COLUMNS.kannactId",
				template: TableColumnTemplate.KANNACT_ID,
			},
			{
				field: "name",
				sortingField: "firstName,lastName",
				title: "PARTICIPANTS.ALERTS.TABLE.COLUMNS.name",
				sortable: true,
			},
			{
				field: "date",
				title: "PARTICIPANTS.ALERTS.TABLE.COLUMNS.date",
				template: TableColumnTemplate.DATE,
				sortable: true,
			},
			{
				field: "reason",
				title: "PARTICIPANTS.ALERTS.TABLE.COLUMNS.reason",
				sortable: true,
				template: TableColumnTemplate.TRANSLATE_PREFIX,
				templateOptions: { translationPrefix: "ENUMS.ALERT_REASON" },
			},
			{
				field: "details",
				title: "PARTICIPANTS.ALERTS.TABLE.COLUMNS.details",
				sortable: true,
			},
			{
				field: "priority",
				title: "PARTICIPANTS.ALERTS.TABLE.COLUMNS.severity",
				sortable: true,
				template: TableColumnTemplate.TAG,
			},
			{
				field: "reachouts",
				title: "PARTICIPANTS.ALERTS.TABLE.COLUMNS.reachouts",
				sortable: true,
			},
			{
				field: "lastAttempt",
				title: "PARTICIPANTS.ALERTS.TABLE.COLUMNS.lastAttempt",
				template: TableColumnTemplate.DATE,
				sortable: true,
			},
			{
				field: "actionsTemplate",
				template: TableColumnTemplate.BUTTONS,
				sortable: false,
				alignment: "right",
			},
		];

		if (HelperService.isAdminViewMode()) {
			const column = {
				field: "coachId",
				title: "PARTICIPANTS.ALERTS.TABLE.COLUMNS.coachId",
				template: TableColumnTemplate.COACH,
				sortable: true,
			};
			columns.splice(2, 0, column);
		}

		return columns;
	};

	// Rows
	const [rows, setRows] = useState<AlertRow[]>();

	// Messages
	const messages = new TableMessages();
	const [message, setMessage] = useState<UiFieldMessageProps>({
		severity: FieldMessageSeverity.INFO,
		label: messages.empty,
	});

	/* Filters */

	const isTestFilter: ITableFilter = {
		field: "isTest",
		type: TableFilterType.SELECT_MULTIPLE,
		options: {
			columnClass: "col-12 col-md-2 col-lg-2 col-xl-1",
			props: {
				label: "PARTICIPANTS.DASHBOARD.FILTERS.IS_TEST_TITLE",
				options: [
					{
						id: "true",
						label: "ENUMS.YES",
					},
					{
						id: "false",
						label: "ENUMS.NO",
					},
				],
			},
		},
		value: ["false"],
	};

	const severityFilter: ITableFilter = {
		field: "severity",
		type: TableFilterType.SELECT_MULTIPLE,
		order: false,
		options: {
			props: {
				label: "PARTICIPANTS.ALERTS.TABLE.FILTER_TITLE_SEVERITY",
				options: Object.keys(AlertSeverity).map(
					(x) => new ListOption({ id: x, label: `ENUMS.ALERT_SEVERITY.${x}` })
				),
				panelHeaderTemplate: undefined,
			},
		},
		value: [],
	};

	const participantStatusFilter: ITableFilter = {
		field: "participantStatus",
		type: TableFilterType.SELECT_MULTIPLE,
		options: {
			props: {
				label: "PARTICIPANTS.ALERTS.TABLE.FILTER_PARTICIPANT_STATUS",
				options: [
					{
						id: eParticipantStatus.PROSPECTIVE,
						label: "ENUMS.PARTICIPANT_STATUS.PROSPECTIVE",
					},
					{
						id: eParticipantStatus.ACTIVE,
						label: "ENUMS.PARTICIPANT_STATUS.ACTIVE",
					},
					{ id: eParticipantStatus.DNC, label: "ENUMS.PARTICIPANT_STATUS.DNC" },
					{
						id: eParticipantStatus.INACTIVE,
						label: "ENUMS.PARTICIPANT_STATUS.INACTIVE",
					},
					{
						id: "null",
						label: "ENUMS.PARTICIPANT_STATUS.NOT_COMPUTED",
					},
				],
			},
		},
		value: ["ACTIVE"],
	};

	const reasonFilter: ITableFilter = {
		field: "reason",
		type: TableFilterType.SELECT_MULTIPLE,
		options: {
			props: {
				label: "PARTICIPANTS.ALERTS.TABLE.FILTER_TITLE_REASON",
				options: Object.keys(AlertReason).map(
					(x) => new ListOption({ id: x, label: `ENUMS.ALERT_REASON.${x}` })
				),
				panelHeaderTemplate: undefined,
			},
		},
		value: [AlertReason.NO_RECENT_READINGS, AlertReason.SUSTAINED_OUT_OF_RANGE_READING],
	};

	const coachFilter: ITableFilter = {
		field: "coachId",
		type: TableFilterType.SELECT_MULTIPLE,
		value: HelperService.isAdminViewMode() ? [] : [`${getSessionStorageUser().id}`],
	};

	const institutionsFilter: ITableFilter = {
		field: "institutionId",
		type: TableFilterType.SELECT_MULTIPLE,
		value: [],
	};

	const setProviders = async () => {
		const providers = await EndpointsService.dataRetriever.getProvidersTwilio();
		const tmpCoachFilter: ITableFilter = Object.assign({}, coachFilter, {
			options: {
				props: {
					label: "PARTICIPANTS.DASHBOARD.FILTERS.COACH_TITLE",
					options:
						providers?.length > 0
							? providers.map(
									(x) =>
										new ListOption({
											id: x.coachId,
											label: Formatter.fullName(x.firstName, x.lastName),
										})
								)
							: [],
					filter: true,
				},
			},
		});
		let updatedFilters: ITableFilter[] = [...filters!.filters, tmpCoachFilter];
		// Filter: Institution
		const institutions = await EndpointsService.dataRetriever.getInstitutionsDetailed();
		// Map filter: institutions
		if (institutions?.length > 0 && !Roles.isProvider()) {
			const tmpInstitutionFilter = Object.assign({}, institutionsFilter, {
				options: {
					props: {
						label: "PARTICIPANTS.DASHBOARD.FILTERS.INSTITUTION_TITLE",
						// options: institutions,
						options:
							institutions?.length > 0
								? institutions.map(
										(x) =>
											new ListOption({
												id: x.institutionId,
												label: x.institutionName,
											})
									)
								: [],
						filter: true,
					},
				},
			});
			updatedFilters = [...updatedFilters, tmpInstitutionFilter];
		}

		// Update filters
		setFilters({ ...filters, filters: updatedFilters });

		// Update stored criteria
		StoredCriteriaService.setFilters(StoredCriteriaService.KEYS["participants-alerts"], {
			...filters,
			filters: updatedFilters,
		});
	};

	const [filters, setFilters] = useState<ITableHeaderFilter>(
		StoredCriteriaService.getFilters(StoredCriteriaService.KEYS["participants-alerts"]) ?? {
			filters: [participantStatusFilter, severityFilter, reasonFilter, isTestFilter],
		}
	);

	// Pagination
	const [count, setCount] = useState<number>(0);
	const [pagination, setPagination] = useState<TablePagination>(
		StoredCriteriaService.getPagination(StoredCriteriaService.KEYS["participants-alerts"]) ?? {
			first: 0,
			rows: 10,
			page: 0,
			sortField: "date",
			sortOrder: -1,
			search: undefined,
			filters: filters.filters,
		}
	);

	/* DATA */

	useEffect(() => {
		// Get data
		getData(pagination);
		getCount(pagination);

		if (
			HelperService.isAdminViewMode() &&
			!StoredCriteriaService.getFilters(StoredCriteriaService.KEYS["participants-alerts"])
		) {
			setProviders();
		}
	}, []);

	const getData = async (event: TablePagination): Promise<void> => {
		// Reset previous data
		setRows([]);

		// Set the loading spinner
		setMessage({
			severity: FieldMessageSeverity.LOADING,
			label: messages.loading,
		});

		// Update pagination object
		const updatedEvent: ITablePagination = {
			...event,
			sortField: HelperService.compoundSortField(setColumns(), event),
		};

		// Coach view: add coachId filter by default
		if (!HelperService.isAdminViewMode()) {
			updatedEvent.filters = [...((filters?.filters as ITableFilter[]) ?? []), coachFilter];
		}

		await EndpointsService.dataRetriever
			.getAlerts({
				config: {
					params: new BackendPagination(updatedEvent),
				},
			})
			.then((response: Alert[]) => {
				if (response?.length > 0) {
					setRows(
						response.map((rowData) => {
							let x = { ...rowData, details: getAlertDetails(rowData.details) };
							return new AlertRow(x, resolve.bind(this));
						})
					);
				} else {
					setMessage({
						severity: FieldMessageSeverity.INFO,
						label: messages.empty,
					});
				}
			})
			.catch(() =>
				setMessage({
					severity: FieldMessageSeverity.DANGER,
					label: messages.error,
				})
			);
	};

	const getAlertDetails = (details: string): string => {
		var prefix = "ENUMS.ALERT_DETAILS.";
		return details.startsWith(AlertDetails.NO_READINGS)
			? t(prefix + AlertDetails.NO_READINGS, {
					days: Number(details.replace(AlertDetails.NO_READINGS, "")),
				})
			: t(prefix + details);
	};

	const getCount = async (event: TablePagination): Promise<void> => {
		// Update pagination object
		const updatedEvent: ITablePagination = { ...event };

		// Coach view: add coachId filter by default
		if (!HelperService.isAdminViewMode()) {
			updatedEvent.filters = [...((filters?.filters as ITableFilter[]) ?? []), coachFilter];
		}

		const queryParams = new BackendPagination(updatedEvent);
		await EndpointsService.dataRetriever
			.getAlertsCount({
				config: {
					params: {
						search: queryParams.search,
						filters: queryParams.filters,
					},
				},
			})
			.then((response: number) => {
				if (response || response === 0) setCount(response);
			})
			.catch(() => {
				console.log("Participants: Error getting the count");
			});
	};

	/* ACTIONS */

	const resolve = async (e: TableTemplateButtonsEvent) => {
		const entity = (e.row as AlertRow).entity;

		await EndpointsService.dataRetriever
			.updateAlert({
				body: {
					id: entity.id,
					entityId: entity.entityId,
					state: AlertState.RESOLVED,
				},
			})
			.then((response) => {
				getData(pagination);
				getCount(pagination);
			})
			.catch(() => console.error("[ERROR] Couldn't update the alert"));
	};

	return (
		<UiTable
			useAsCard={true}
			dataKey="id"
			title={
				HelperService.isAdminViewMode()
					? "PARTICIPANTS.ALERTS.TABLE.TITLE_ADMIN"
					: "PARTICIPANTS.ALERTS.TABLE.TITLE_COACH"
			}
			columns={setColumns()}
			value={rows}
			message={message}
			isServerPaginated={true}
			pagination={pagination}
			paginationFn={(event: TablePagination) => {
				// When selected page changes, update the pagination state
				const newPagination: TablePagination = { ...pagination, ...event };
				setPagination(newPagination);

				// Update stored criteria
				StoredCriteriaService.setPagination(
					StoredCriteriaService.KEYS["participants-alerts"],
					newPagination
				);

				// Get data
				getData(newPagination);
			}}
			totalRecords={count}
			search={{
				value:
					StoredCriteriaService.getPagination(
						StoredCriteriaService.KEYS["participants-alerts"]
					)?.search ?? undefined,
				fn: (value: string | null | undefined) => {
					// Update pagination state
					const newPagination: TablePagination = {
						...pagination,
						search: value && value !== "" ? value.trim() : undefined,
						page: 0,
						first: 0,
					};
					setPagination(newPagination);

					// Update stored list pagination/filters
					StoredCriteriaService.setPagination(
						StoredCriteriaService.KEYS["participants-alerts"],
						newPagination
					);

					// Update data
					getData(newPagination);
					getCount(newPagination);
				},
			}}
			filter={filters}
			filterFn={(value: TableFilterFormData) => {
				// Update filters state
				const newFilters = FilterService.updateFiltersValues(
					(filters as ITableHeaderFilter).filters,
					value
				);
				setFilters({ ...filters, filters: newFilters });

				// Update pagination
				const newPagination: TablePagination = {
					...pagination,
					filters: newFilters,
					page: 0,
					first: 0,
				};
				setPagination(newPagination);

				// Update stored criteria
				StoredCriteriaService.set(
					StoredCriteriaService.KEYS["participants-alerts"],
					newPagination,
					{
						...filters,
						filters: newFilters,
					}
				);

				// Update table message
				setMessage({
					severity: FieldMessageSeverity.LOADING,
					label: messages.filter,
				});

				// Get data
				getData(newPagination);
				getCount(newPagination);
			}}
		/>
	);
};

export default ParticipantsAlerts;
