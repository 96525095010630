import { useTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";
import { UiModalSentEmailsProps } from "../../models/components/modal-sentemails.model";
import { Formatter } from "../../services/formatter/formatter.service";
import DOMPurify from "dompurify";

const UiModalSentEmails = (props: UiModalSentEmailsProps) => {
	const { t } = useTranslation("common");

	// Modal title
	const setModalTitle = (): string => t("PARTICIPANT.MESSAGES.SENT_EMAILS.MODAL.TITLE");

	const content = props.entity?.content ? DOMPurify.sanitize(props.entity.content) : undefined;

	return (
		<Dialog
			header={setModalTitle}
			visible={props.isVisible}
			breakpoints={{ "960px": "75vw", "640px": "100vw" }}
			style={{ width: "70vw" }}
			modal={true}
			draggable={false}
			resizable={false}
			focusOnShow={false}
			onHide={() => {
				props.closeModal();
			}}
		>
			<div className="form-section__content">
				<table>
					<tr>
						<td className="col-3 participant-modal-sent-emails__cell">
							<label className="text-bold me-2">
								{t("PARTICIPANT.MESSAGES.SENT_EMAILS.MODAL.EMAIL_REFERENCE")}
							</label>
							<br />
							<span>{props.entity?.ref ? props.entity.ref : "-"}</span>
						</td>
						<td className="col-3 participant-modal-sent-emails__cell">
							<label className="text-bold me-2">
								{t("PARTICIPANT.MESSAGES.SENT_EMAILS.MODAL.STATUS")}
							</label>
							<br />
							<span>{props.entity?.status ? props.entity.status : "-"}</span>
						</td>
						<td className="col-3 participant-modal-sent-emails__cell">
							<label className="text-bold me-2">
								{t("PARTICIPANT.MESSAGES.SENT_EMAILS.MODAL.FROM")}
							</label>
							<br />
							<span>{props.entity?.from ? props.entity.from : "-"}</span>
						</td>
						<td className="col-3 participant-modal-sent-emails__cell">
							<label className="text-bold me-2">
								{t("PARTICIPANT.MESSAGES.SENT_EMAILS.MODAL.TO")}
							</label>
							<br />
							<span>{props.entity?.to ? props.entity.to : "-"}</span>
						</td>
					</tr>
				</table>
				<br />
				<table>
					<tr>
						<td className="col-6 participant-modal-sent-emails__cell">
							<label className="text-bold me-2">
								{t("PARTICIPANT.MESSAGES.SENT_EMAILS.MODAL.SUBJECT_LINE")}
							</label>
							<br />
							<span>{props.entity?.subject ? props.entity.subject : "-"}</span>
						</td>
						<td className="col-3 participant-modal-sent-emails__cell">
							<label className="text-bold me-2">
								{t("PARTICIPANT.MESSAGES.SENT_EMAILS.MODAL.DATE")}
							</label>
							<br />
							<span>
								{Formatter.dateISOToString(
									props.entity?.timestamp
										? new Date(props.entity.timestamp).toISOString()
										: "",
									{
										day: "2-digit",
										month: "2-digit",
										year: "2-digit",
										hour: "2-digit",
										minute: "2-digit",
										hour12: true,
										timeZoneName: "shortGeneric",
									}
								) ?? Formatter.NO_DATA}
							</span>
						</td>
						<td className="col-3 participant-modal-sent-emails__cell">
							<label className="text-bold me-2">
								{t("PARTICIPANT.MESSAGES.SENT_EMAILS.MODAL.ORIGIN")}
							</label>
							<br />
							<span>
								{props.entity?.origin
									? t(
											`PARTICIPANT.MESSAGES.SENT_EMAILS.ORIGINS.${props.entity.origin}`
										)
									: "-"}
							</span>
						</td>
					</tr>
				</table>
			</div>
			<br />
			<div
				className="form-section__content"
				dangerouslySetInnerHTML={content ? { __html: content } : undefined}
			/>
		</Dialog>
	);
};

export default UiModalSentEmails;
