import { EndpointRequest } from "./endpoints/endpoints.model";

/* ENTITY */

export interface Procedure {
	id: number;
	participantId: number;
	dateOn: Date;
	type: ProcedureType;
	status: ProcedureStatus;
	notes: string | null;
	createdOn: string; // Date
	updatedOn: string; // Date
	deletedOn: string | null; // Date
}

export enum ProcedureStatus {
	COMPLETED = "COMPLETED",
	SCHEDULED = "SCHEDULED",
}

export enum ProcedureType {
	GLUCOSE_TOLERANCE_TEST = "GLUCOSE_TOLERANCE_TEST",
	BIRTH = "BIRTH",
	DISCHARGED_FROM_HOSPITAL = "DISCHARED_FROM_HOSPITAL",
	HOSPITAL_ADMISSION = "HOSPITAL_ADMISSION",
}

/* OTHER */

export interface ProcedureCreateForm {
	dateOn: Date | null;
	type: ProcedureType | null;
	status: ProcedureStatus | null;
	notes: string | null;
}

/* ENDPOINTS */

export interface ProcedureGetRequest extends EndpointRequest {
	config: {
		params: {
			participantId: number;
		};
	};
}

export interface ProcedureCreateRequest extends EndpointRequest {
	body: {
		participantId: number;
		dateOn: string; // Date
		type: ProcedureType;
		status: ProcedureStatus;
		notes: string | null;
	};
}
