export interface Alert {
	id: number;
	kannactId: string;
	firstName: string;
	lastName: string;
	coachId: number;
	entityId: number;
	institutionId: number;
	isTest: boolean;
	type: string;
	severity: AlertSeverity;
	reason: AlertReason;
	details: string;
	date: string; // Date
	version: number;
	resolutionType: string;
	examinations: AlertExamination[] | null;
	reachouts: number;
	lastAttempt: string; // Date
	state: AlertState;
	messages: AlertMessage[];
}

export interface AlertExamination {
	examinationId: number;
	alertId: number;
	examinedBy: number;
	resolution: string;
	resolutionDescription: string;
	date: string;
}

export enum AlertSeverity {
	CRITICAL = "CRITICAL",
	HIGH = "HIGH",
	MEDIUM = "MEDIUM",
	LOW = "LOW",
}

export enum AlertReason {
	CRITICAL_READING = "CRITICAL_READING",
	SUSTAINED_OUT_OF_RANGE_READING = "SUSTAINED_OUT_OF_RANGE_READING",
	NO_RECENT_READINGS = "NO_RECENT_READINGS",
	NO_CALLS_SCHEDULED = "NO_CALLS_SCHEDULED",
}

export enum AlertDetails {
	HIGH_GLUCOSE = "HIGH_GLUCOSE",
	LOW_GLUCOSE = "LOW_GLUCOSE",
	NO_INTRO_CALL = "NO_INTRO_CALL",
	NO_READINGS = "NO_READINGS_FOR_"
}

export enum AlertState {
	INITIAL = "INITIAL",
	RESOLVED = "RESOLVED",
}

export interface AlertMessage {
	id: string;
	kannactId: string;
	timestamp: string; // Date
	channel: string;
	ref: string;
	trackingId: string;
	participantId: number;
	from: string;
	to: string;
	content: string;
	status: string;
	details: string;
}
