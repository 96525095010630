import UiButton from "../../../components/button/Button";
import { useState, useEffect, useRef, useContext } from "react";
import { Dialog } from "primereact/dialog";
import UiFieldMessage from "../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../models/components/field-message.model";
import { useMedicalReport } from "../../../hooks/useMedicalReport";
import { Formatter } from "../../../services/formatter/formatter.service";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";
import axios from "axios";
import { ParticipantContext } from "../../../contexts/Participant.context";
import { ParticipantContextProps } from "../../../models/contexts/participant-context.model";
import { useTranslation } from "react-i18next";

const POLLING_INTERVAL_FOR_REPORT_IN_MS = 5000;
const TIMEOUT_FOR_REPORT_GENERATION_IN_MS = 10 * 60 * 1000;

const ParticipantMetriport = ({ participantId }: { participantId: number }) => {
	const { t } = useTranslation("common");
	const [isVisible, setIsVisible] = useState(false);

	return (
		<div>
			<UiButton
				className="p-button-rounded p-button-sm"
				label={t("PARTICIPANT.DASHBOARD.METRIPORT.GET_REPORT")}
				onClick={() => setIsVisible(true)}
			/>

			{isVisible && (
				<MedicalReportModal
					participantId={participantId}
					visible={isVisible}
					onClose={() => setIsVisible(false)}
				/>
			)}
		</div>
	);
};

const MedicalReportModal = ({
	participantId,
	visible,
	onClose,
}: {
	participantId: number;
	visible: boolean;
	onClose: () => void;
}) => {
	const { t } = useTranslation("common");
	const [isLoadingContent, setIsLoadingContent] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [isGenerating, setIsGenerating] = useState(false);

	const generationDetails = useRef({
		startTime: 0,
		originalReportId: null as string | null,
	});

	const medicalReportQuery = useMedicalReport({
		participantId,
		includeContent: false,
		refetchInterval: POLLING_INTERVAL_FOR_REPORT_IN_MS,
	});

	// Store the initial report ID when data is first loaded and check generation status
	useEffect(() => {
		checkGenerationStatus();

		if (medicalReportQuery.data && !generationDetails.current.originalReportId) {
			generationDetails.current.originalReportId = medicalReportQuery.data.id;
		}
	}, [medicalReportQuery]);

	/**
	 * Checks if report generation is complete or has timed out
	 * - Detects if a new report is available (ID changed)
	 * - Checks if 2 minutes have passed since generation started
	 */
	const checkGenerationStatus = () => {
		if (!isGenerating) return;

		const { startTime, originalReportId } = generationDetails.current;

		const hasNewReport =
			medicalReportQuery.data && medicalReportQuery.data.id !== originalReportId;

		if (hasNewReport) {
			setIsGenerating(false);
			return;
		}

		const elapsedTime = Date.now() - startTime;
		if (startTime > 0 && elapsedTime > TIMEOUT_FOR_REPORT_GENERATION_IN_MS) {
			setIsGenerating(false);
			setError(t("PARTICIPANT.DASHBOARD.METRIPORT.NO_DATA_FOUND"));
		}
	};

	const handleGenerateClick = async () => {
		setIsGenerating(true);
		setError(null);

		generationDetails.current = {
			startTime: Date.now(),
			originalReportId: medicalReportQuery.data?.id || null,
		};

		try {
			await EndpointsService.metriport.generateMedicalReport(participantId);
		} catch (err) {
			setIsGenerating(false);

			if (axios.isAxiosError(err) && err.response?.status === 409) {
				setError(t("PARTICIPANT.DASHBOARD.METRIPORT.ERROR_CREATE_PATIENT"));
			} else {
				setError(t("PARTICIPANT.DASHBOARD.METRIPORT.ERROR_UNKNOWN_GENERATE"));
			}
		}
	};

	const handleViewClick = async () => {
		if (!medicalReportQuery.data) return;

		setIsLoadingContent(true);
		setError(null);

		try {
			const reportWithContent = await EndpointsService.metriport.getMedicalReport({
				participantId,
				includeContent: true,
			});

			if (reportWithContent && reportWithContent.content) {
				const blob = Formatter.base64ToBlob(reportWithContent.content, "application/pdf");
				Formatter.openFile(blob, "application/pdf");
			} else {
				setError(t("PARTICIPANT.DASHBOARD.METRIPORT.ERROR_UNKNOWN_LOAD"));
			}
		} catch (error) {
			setError(t("PARTICIPANT.DASHBOARD.METRIPORT.ERROR_UNKNOWN_LOAD"));
		} finally {
			setIsLoadingContent(false);
		}
	};

	const participantContext = useContext<ParticipantContextProps>(ParticipantContext);
	const participant = participantContext.participant!;

	const patientHasValidShippingAddress =
		participant.shippingAddressAddressLine1 &&
		participant.shippingAddressCity &&
		participant.shippingAddressState &&
		participant.shippingAddressPostalCode;

	const patientHasAllNecessaryData =
		participant.gender &&
		participant.dob &&
		participant.firstName &&
		participant.lastName &&
		patientHasValidShippingAddress;

	if (!patientHasAllNecessaryData) {
		return (
			<ModalLayout
				visible={visible}
				onClose={onClose}
			>
				<div>{t("PARTICIPANT.DASHBOARD.METRIPORT.PROFILE_INCOMPLETE")}</div>
				<div>{t("PARTICIPANT.DASHBOARD.METRIPORT.PROFILE_REQUIREMENTS")}</div>
				<div className="participant-metriport__buttons">
					<UiButton
						className="p-button-rounded p-button-sm"
						label={t("PARTICIPANT.DASHBOARD.METRIPORT.GENERATE")}
						onClick={handleGenerateClick}
						disabled
					/>
					<UiButton
						className="p-button-rounded p-button-sm"
						label={t("PARTICIPANT.DASHBOARD.METRIPORT.VIEW")}
						disabled
					/>
				</div>
			</ModalLayout>
		);
	}

	if (medicalReportQuery.isLoading) {
		return (
			<ModalLayout
				visible={visible}
				onClose={onClose}
			>
				<UiFieldMessage
					severity={FieldMessageSeverity.LOADING}
					label={t("PARTICIPANT.DASHBOARD.METRIPORT.LOADING_REPORT")}
				/>
			</ModalLayout>
		);
	}

	if (medicalReportQuery.data) {
		const date = Formatter.dateISOToString(medicalReportQuery.data.created_on, {
			day: "2-digit",
			month: "2-digit",
			year: "2-digit",
			hour: "2-digit",
			minute: "2-digit",
		});

		return (
			<ModalLayout
				visible={visible}
				onClose={onClose}
			>
				<div>
					{t("PARTICIPANT.DASHBOARD.METRIPORT.LAST_REPORT")} {date}
				</div>
				<div className="participant-metriport__buttons">
					<UiButton
						className="p-button-rounded p-button-sm"
						label={t("PARTICIPANT.DASHBOARD.METRIPORT.REGENERATE")}
						onClick={handleGenerateClick}
						disabled={isLoadingContent || isGenerating}
					/>
					<UiButton
						className="p-button-rounded p-button-sm"
						label={t("PARTICIPANT.DASHBOARD.METRIPORT.VIEW")}
						onClick={handleViewClick}
						disabled={isLoadingContent || isGenerating}
					/>
				</div>
				{isLoadingContent && (
					<div className="participant-metriport__message-wrapper">
						<UiFieldMessage
							severity={FieldMessageSeverity.LOADING}
							label={t("PARTICIPANT.DASHBOARD.METRIPORT.LOADING_CONTENT")}
						/>
					</div>
				)}
				{isGenerating && (
					<div className="participant-metriport__message-wrapper">
						<UiFieldMessage
							severity={FieldMessageSeverity.LOADING}
							label={t("PARTICIPANT.DASHBOARD.METRIPORT.GENERATING_REPORT")}
						/>
					</div>
				)}
				{error && (
					<div className="participant-metriport__message-wrapper">
						<UiFieldMessage
							severity={FieldMessageSeverity.DANGER}
							label={error}
						/>
					</div>
				)}
			</ModalLayout>
		);
	}

	return (
		<ModalLayout
			visible={visible}
			onClose={onClose}
		>
			<div>{t("PARTICIPANT.DASHBOARD.METRIPORT.NO_REPORT")}</div>
			<div className="participant-metriport__buttons">
				<UiButton
					className="p-button-rounded p-button-sm"
					label={t("PARTICIPANT.DASHBOARD.METRIPORT.GENERATE")}
					onClick={handleGenerateClick}
					disabled={isGenerating}
				/>
				<UiButton
					className="p-button-rounded p-button-sm"
					label={t("PARTICIPANT.DASHBOARD.METRIPORT.VIEW")}
					disabled
				/>
			</div>
			{isGenerating && (
				<div className="participant-metriport__message-wrapper">
					<UiFieldMessage
						severity={FieldMessageSeverity.LOADING}
						label={t("PARTICIPANT.DASHBOARD.METRIPORT.GENERATING_REPORT")}
					/>
				</div>
			)}
			{error && (
				<div className="participant-metriport__message-wrapper">
					<UiFieldMessage
						severity={FieldMessageSeverity.DANGER}
						label={error}
					/>
				</div>
			)}
		</ModalLayout>
	);
};

const ModalLayout = ({
	children,
	visible,
	onClose,
}: {
	children: React.ReactNode;
	visible: boolean;
	onClose: () => void;
}) => {
	const { t } = useTranslation("common");

	return (
		<Dialog
			header={t("PARTICIPANT.DASHBOARD.METRIPORT.MODAL_TITLE")}
			visible={visible}
			breakpoints={{ "960px": "50vw", "640px": "100vw" }}
			style={{ width: "40vw" }}
			modal={true}
			draggable={false}
			resizable={false}
			focusOnShow={false}
			onHide={onClose}
		>
			{children}
		</Dialog>
	);
};

export default ParticipantMetriport;
